.inner-container {
	margin-top: 145px;


	.page-title {
		padding-bottom: 0px;
		margin-bottom: 10px;
	}

	.pad40 {
		padding: 40px 0px;
	}

	.body-text {
		p {
			color: var(--green);
		}
	}

	p {
		line-height: 1.8em;
	}

	.mt-30 {
		margin-top: 30px;
	}

	.padl25 {
		padding-left: 25px;
	}

	.mt-20-15 {
		margin-top: 20px;
		margin-bottom: 15px;
	}

	.seasonoffers .lt-bl-cont {
		background-color: #f3f3f3;

		h4 {
			min-height: 45px;
		}
	}

	.divider.small-gap {
		margin: 20px 0px;
	}

	.team-block {
		display: block;
		padding: 4px;
		margin-bottom: 20px;
		line-height: 1.42857;
		background-color: #fff;
		border: 1px solid #ddd;
		border-radius: 4px;

		@media (max-width: 767px) {
			max-width: 440px;
			margin: 0 auto 20px;
		}

		img {
			width: 100%;
		}
	}

	.caption {
		padding: 9px;
		color: #888e99;
	}


	.mt-10 {
		margin-top: 10px;
	}

	.image-heading-bl {
		text-align: center;

		.heading-bl {
			text-align: left;

			h3 {
				font-size: 26px;
			}
		}

		.heading-bl-content {
			min-height: 180px;
			padding-top: 20px;

			@media (max-width:768px) {
				min-height: 130px;
			}
		}

		p {
			@media (max-width:768px) {
				min-height: 130px;
			}
		}

		.serv-img {
			margin-bottom: 5px;
			display: block;
			height: 185px;
			overflow: hidden;

			img {

				object-fit: cover;
				width: 100%;
				height: 100%;

			}
		}

		.btn {
			text-transform: lowercase;
		}

		margin:15px 0 15px;

	}


	.services-table {
		.table-striped {
			tr {
				td {
					border: none;

					@media (max-width:992px) {
						display: block;
						padding-top: 0;
					}
				}

				th {
					max-width: 285px;
					border-top: 0px !important;
					padding: 15px !important;

					@media (max-width:992px) {
						display: block;
						max-width: inherit;
						padding-bottom: 0;
					}
				}
			}
		}

	}

	.widgt-grey-bl {
		max-width: 640px;
		margin: 0 auto;

		.form-control {
			padding-left: 10px;
		}
	}

	.lt-bl-cont {
		.bl-title {
			color: var(--black2);

			&:hover {
				color: #626872;
			}
		}

		p {
			line-height: 1.4;
		}
	}


}


.ct-form-section {
	.mapnote {
		margin: 0 0 15px 0;
		font-size: 14px;
	}

	.mapconsent {
		background: rgba(26, 121, 187, .15);
		padding: 15px;

		a {
			background: var(--primary);
			color: #fff;
			border-radius: 5px;
			padding: 0 15px;
			height: 36px;
			display: inline-block;
			line-height: 35px;
			margin: 0 0 15px 0;

			&:hover {
				background: var(--secondary);
			}
		}
	}
}

.Anreise-Sylt {
	.double-arrow-li {
		li {
			@media (max-width: 767px) {
				padding: 5px 0 5px 20px;
			}

			a {
				word-break: break-all;
			}

		}
	}
}