@import "setup.css";

.vo-nav-bar {
	padding: 0;
	border-bottom: 1px solid var(--primary);
	z-index: 50;
}



header {
	background-color: var(--white);
	position: relative;
	z-index: 50 !important;

	@media (max-width:992px) {
		position: fixed;
		width: 100%;
		z-index: 10;
		top: 0;
	}

	.header-middle {
		.container {
			@media (max-width:992px) {
				width: 100%;
			}
		}
	}

	.header-top {
		background-color: var(--white);
		padding: 3px 0 8px;
		text-align: right;
		font-size: 13px;

		.input-bl-tp {
			padding: 5px 15px;
			max-width: 280px;

			@media (max-width:992px) {
				margin-bottom: 5px;
			}

			.form-control {
				padding: 5px 10px;
				width: 200px;

			}

			.btn {
				border-radius: 0 5px 5px 0;
				padding: 9px 16px;
			}
		}

		ul {
			padding: 0;
			margin: 0;
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;

			li {
				display: inline-block;
				position: relative;

				@media (max-width:992px) {
					display: block;
				}

				a {
					color: var(--black2);
					text-decoration: none;
					font-size: 13px;
					padding: 5px 15px;

					@media (max-width:550px) {
						padding: 5px 4px;
					}

					&:hover {
						color: var(--green);
						text-decoration: underline;
					}

					i.fa {
						font-size: 14px;
						margin-right: 5px;
					}
				}

				&.fav-count {

					.sub-count {
						-moz-border-radius: 50%;
						-webkit-border-radius: 50%;
						border-radius: 50%;
						display: block;
						position: absolute;
						top: -6px;
						left: 7px;
						width: 14px;
						height: 14px;
						background: red;
						background: var(--red);
						color: var(--white);
						text-align: center;
						font-size: 10px;
						line-height: 16px;
					}
				}
			}
		}
	}

	.header-main {

		.header-logo {
			display: flex;
			align-items: center;

			@media (max-width:992px) {
				max-width: 120px;
			}


			.logo {

				@media (max-width:992px) {
					border-right: none;
					padding-right: 0;
					margin-right: 0;
				}
			}
		}

		.btn-box {
			@media (max-width:992px) {
				border: 1px solid var(--primary);
				padding: 5px 7px;

				display: inline-block;
				margin-right: 5px;
			}

			.fa {
				@media (max-width:992px) {
					font-size: 16px;
				}
			}
		}

		.search-box {
			@media (max-width:992px) {
				background-color: var(--primary);
				color: var(--white);
				font-size: 16px;
				padding: 5px 8px;
			}
		}





		.header-hrs {
			.main-menu {


				@media (max-width:992px) {
					position: absolute;
					left: 0;
					top: 100%;
				}

				>ul {
					padding: 0;
					margin: 0;

					>li {
						float: left;
						display: inline-block;

						@media (max-width:992px) {
							display: block;
							width: 100%;
						}

						&:last-child {
							margin-right: 0;
						}

						>a {
							color: var(--black2);
							text-transform: uppercase;
							font-size: 18px;
							padding: 28px 30px;
							line-height: 1;
							display: block;
							font-weight: 400;

							@media (max-width:1199px) {
								padding: 28px 15px;
							}

							@media (max-width:992px) {
								padding: 10px 15px;
								text-transform: capitalize;
								font-size: 14px;
							}


							&:hover {
								color: var(--white);
								background-color: var(--primary);
							}
						}

						&.dropdown {
							position: initial;
							cursor: pointer;

							.dropdown-backdrop {
								display: none;
							}

							&.open {
								a.dropdown-toggle {
									background-color: var(--primary);
									color: var(--white);
								}
							}

							.mega-menu {
								border-radius: 0;
								border: none;
								box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
								padding: 0;
								margin: 0;
								width: 100%;
								padding-bottom: 60px;

								@media (max-width:992px) {
									background-color: var(--black2);
									position: relative;
									padding-bottom: 0;
									box-shadow: initial;
									border-radius: 0 0 5px 5px;
								}

								.menu-title {
									background-color: var(--primary);
									color: var(--white);
									padding: 15px 0;
									text-transform: uppercase;
								}

								.sub-menu {
									padding-top: 20px;

									@media (max-width:992px) {
										padding-top: 0;
										padding-bottom: 20px;
									}

									li {
										a {
											padding: 10px 0;
											font-weight: 700;
											color: var(--black2);
											display: block;
											font-size: 14px;
											text-transform: uppercase;

											@media (max-width:992px) {
												color: var(--white);
												padding: 10px;
												font-weight: 400;
												text-transform: initial;
											}

											&:hover {
												color: var(--primary);
											}
										}
									}

									ul {
										padding-left: 20px;
									}
								}

								.image-grey-bl {
									max-width: 740px;
									display: inline-block;

									.img-cont-bl {
										background-color: var(--grey4);
										margin: 5px 0 0;
										padding: 15px 30px 30px;
										color: var(--gren);
										text-align: left;
									}
								}
							}
						}
					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}

			.fa-clock-o {
				@media (max-width:992px) {
					display: none;
				}
			}
		}
	}
}

.icon {
	display: none;
}

.navactive {
	color: var(--white) !important;
	background-color: var(--primary);
}



@media screen and (max-width: var(--navbarbreak)) {
	.vo-nav-bar {
		padding: 0;

		.main-menu {
			background-color: var(--white);
			padding: 5px 0;
			z-index: 50;
			display: none;
			border-top: none;
			width: 100%;
			border-bottom: 1px solid #61847a;
		}

		&.opened {
			.main-menu {
				display: block;
				max-height: 350px;
				overflow-y: scroll;
			}
		}
	}

	.vo-nav-bar .icon {
		position: relative;
		float: right;
		padding: 9px 10px;
		margin-top: 8px;
		margin-bottom: 8px;
		background-color: transparent;
		background-image: none;
		border: 1px solid #ddd;
		border-radius: 4px;

		span {
			background-color: #888;
			display: block;
			width: 22px;
			height: 2px;
			margin-top: 4px;
			border-radius: 1px;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	.icon {
		display: block;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
	}

	.vo-nav-bar.opened .vo-dropdown-content {
		position: relative;
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		display: block;
		width: 100%;
		text-align: left;
	}

}

.sidenav {
	position: fixed;
	top: 210px;
	right: -75px;
	z-index: 5;
	transition: ease .3s;
	-o-transition: ease .3s;

	-webkit-transition: ease .3s;

	@media (max-width:992px) {
		display: none;
	}


	&:hover {
		right: 0;
	}

	.nav-title {
		display: flex;
		color: var(--grey3);
		padding: 10px 12px;
		position: relative;
		background-color: var(--white);
		margin-bottom: 1px;
		box-shadow: 0 0 1px rgba(0, 0, 0, .5) inset;
		height: 92px;
		align-items: center;


		&:hover {
			color: var(--primary);
			text-decoration: none;
		}



		i {
			font-size: 28px;
			margin-right: 12px;
		}
	}
}