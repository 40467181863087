.main-footer-area {
	.bg-footer {
		/* background-image: url(RESOURCE/img/bg-footer-neu.jpg); 
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;*/
		position: relative;
		padding: 75px 0 120px;
		overflow: hidden;

		&:before {
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.5);
			left: 0;
			top: 0;
			position: absolute;
			content: "";
			z-index: 1;
		}

		.bg-footer-img {
			width: 100%;
			height: 314px;
			object-fit: cover;
			position: absolute;
			top: 0;
			bottom: -75px;

			@media (max-width: 400px) {
				height: 403px;

			}

			@media all and (min-width: 401px) and (max-width: 767px) {
				height: 385px;
			}
		}

		.container {
			position: relative;
			z-index: 2;

			@media (max-width: 400px) {
				max-width: 375px;
				width: 100%;
			}

			@media all and (min-width: 401px) and (max-width: 767px) {
				max-width: 400px;
				width: 100%;
			}

			.headline {
				h2 {
					@media (max-width: 375px) {
						padding-left: 20px;
						padding-right: 20px;
					}
				}

			}
		}
	}

	.footer-cont {
		position: relative;
		margin: 25px 0;
		z-index: 1;

		.icon-logo {
			display: inline-block;
			position: relative;
			top: -15px;
		}

		a {
			color: var(--black2);
			text-decoration: none;

			&:hover {
				color: var(--green);
			}
		}

		p {
			color: var(--black2);
			line-height: 1.5;
			font-size: 13px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.social-bl {
			text-align: center;

			a {
				width: 40px;
				height: 40px;
				border-radius: 5px;
				font-size: 20px;
				background-color: var(--black2);
				color: var(--white);
				margin: 0 5px;
				display: inline-block;
				line-height: 44px;

				&:hover {
					background-color: var(--primary);
				}
			}
		}
	}

	.newsletter-bl {
		background-color: var(--grey);
		padding: 30px 0;

		.newslet-flex-row {
			@media (min-width:993px) {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			text-align:center;

			.newst-title {
				position: relative;
				padding-left: 50px;

				@media (max-width:992px) {
					padding-left: 0;
					margin-bottom: 10px;
				}

				i {
					height: 40px;
					width: 40px;
					text-align: center;
					line-height: 40px;
					background-color: var(--primary);
					border-radius: 50%;
					border: 1px solid var(--white);
					-webkit-box-shadow: 0 0 0 2px var(--primary);
					box-shadow: 0 0 0 2px var(--primary);

					left: 0;
					font-size: 22px;
					color: var(--white);

					@media (min-width:993px) {
						position: absolute;
					}
				}

				h3 {
					color: var(--white);
					font-size: 24px;
					margin: 0;
					text-align: left;

					@media (max-width:992px) {
						text-align: center;
					}
				}

				span {
					color: var(--white);
				}
			}

			.newslet-form-bl {

				@media (min-width:993px) {
					display: flex;
				}

				.form-control {
					padding: 6px 12px;
					font-size: 14px;
					line-height: 1.42857;
					border-radius: 4px;
					color: var(--green);
					background-color: var(--white);
					background-image: none;
					border: 1px solid #c3c6cc;
					width: 214px;
					margin-right: 3px;

					@media (max-width:992px) {
						width: 100%;
						margin-bottom: 5px;
					}
				}
			}
		}
	}

	.footer-black {
		background-color: #2e2d2c;
		color: var(--white);
		font-size: 18px;
		padding-top: 60px;
		min-height: 270px;

		p {
			font-size: 18px;
		}

		.ft-nav {
			li {
				list-style: none;
				display: inline-block;
				position: relative;

				&:before {
					border-left: 1px solid #9d9d9c;
					content: "";
					height: 15px;
					position: absolute;
					left: -2px;
					top: 4px;
				}

				&:first-child {
					&:before {
						display: none;
					}

					a {
						margin-left: 0;
					}
				}

				a {
					margin: 0 10px;
					font-size: 16px;
					color: var(--white);
					text-transform: capitalize;

					&:hover {
						color: var(--primary);
					}
				}
			}
		}

		.footer-comp-dt {
			text-transform: uppercase;
			color: var(--white);
			position: relative;

			@media (max-width:992px) {
				margin: 50px 0;
				max-width: 330px;
			}

			span {
				font-family: var(--font-heading);

				&.tel-row1 {
					font-size: 55px;
					line-height: 40px;
				}

				&.tel-row2 {
					font-size: 22px;
					margin-bottom: 10px;
					display: block;
				}

				&.tel-row3 {
					font-size: 22px;
					display: block;
					margin-top: 0;
					position: relative;
					padding: 0 0 0 42px;
					z-index: 1;

					a {
						color: #fff;

						&:hover {
							color: var(--primary);
						}
					}

					@media (max-width: 991px) {
						padding: 0 0 0 30px;
					}

					.fa {
						position: absolute;
						left: 13px;
						top: 3px;

						@media (max-width: 991px) {
							left: 0;
						}
					}
				}
			}



			i.icon-call {

				@media (min-width:993px) {
					position: absolute;
					top: -60px;
					left: -20px;
					opacity: 0.1;
					background-color: #ffffff;
					color: #2e2d2c;
					-webkit-border-radius: 50%;
					-moz-border-radius: 50%;
					-ms-border-radius: 50%;
					border-radius: 50%;
					width: 250px;
					height: 250px;
					text-align: center;
					line-height: 250px;
					font-size: 80px;
				}

				@media (max-width:992px) {
					display: none;
				}
			}
		}
	}
}