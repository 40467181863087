.indexpage {

	.offerbutton {
		border: none;
		border-radius: 0;
		font-family: var(--font-heading);
		padding-top: 0;
		padding-bottom: 0;
		overflow: hidden;

		.btn {

			font-family: var(--font-heading);
			position: relative;
			border: 1px solid var(--white);
			border-radius: 0;
			height: 44px;
			text-transform: initial;

			@media (min-width:768px) {
				font-size: 24px;
				height: 52px;
			}

			i {
				display: inline-block;
				position: absolute;
				color: var(--white);
				line-height: 50px;
				font-size: 100px;
				opacity: 0.5;
				font-style: normal;
				margin-left: -85px;
				margin-top: -15px;
			}
		}
	}


	.cat-img-bl {
		height: 350px;
		position: relative;
		overflow: hidden;
		display: block;
		margin-bottom: 30px;

		@media (max-width:992px) {
			height: 210px;
		}

		@media (max-width:767px) {
			height: 150px;
		}

		img {
			-o-object-fit: cover;
			object-fit: cover;
			height: 100%;
			width: 100%;
			color: #FFFFFF;
		}

		&:hover {
			&:before {
				background-color: rgba(88, 96, 106, 0.7);
			}

			.cat-overlay {
				top: 20%;


				hr {
					width: 80%;
					-webkit-transition: width 0.5s ease 0.7s;
					-moz-transition: width 0.5s ease 0.7s;
					-o-transition: width 0.5s ease 0.7s;
					transition: width 0.5s ease 0.7s;
				}

				p {
					opacity: 1;
					-webkit-transition: opacity 0.5s ease 0.7s;
					-moz-transition: opacity 0.5s ease 0.7s;
					-o-transition: opacity 0.5s ease 0.7s;
					transition: opacity 0.5s ease 0.7s;
				}
			}
		}

		&:before {
			background-color: rgba(128, 112, 112, 0.5);
			transition: background-color 0.5s ease;
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}


		.cat-overlay {
			position: absolute;
			text-align: right;
			z-index: 2;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			padding: 30px;
			top: 70%;
			-webkit-transition: top 0.5s ease-in-out;
			-moz-transition: top 0.5s ease-in-out;
			-o-transition: top 0.5s ease-in-out;
			transition: top 0.5s ease-in-out;

			@media (max-width:992px) {
				top: 60%;
			}

			@media (max-width:767px) {
				text-align: left;
				top: 55% !Important;
			}

			hr {
				margin-top: 15px;
				border-top: 1px solid #f3f3f3;
				margin-bottom: 15px;
				width: 0%;
				-webkit-transition: width 0.5s ease;
				-moz-transition: width 0.5s ease;
				-o-transition: width 0.5s ease;
				transition: width 0.5s ease;
				display: inline-block;

				@media (max-width:767px) {
					display: none;
				}
			}

			.cat-main-title {
				font-size: 24px;
				color: var(--white);
				font-family: var(--bodyfont);
				font-weight: bold;
				margin: 0;

				@media (max-width:992px) {
					font-size: 18px;
				}
			}

			p {
				color: var(--white);
				font-family: var(--font-heading);
				-webkit-transition: opacity 0.5s ease;
				-moz-transition: opacity 0.5s ease;
				-o-transition: opacity 0.5s ease;
				transition: opacity 0.5s ease;
				opacity: 0;
				font-size: 14px;

				@media (max-width:992px) {
					display: none;
				}
			}
		}
	}

	.week-plan-bl {
		background-color: var(--lightblue);
		margin-top: 60px;
		padding: 0 15px;

		img {
			width: 100%;
		}

		.week-bl-content {
			padding: 45px;
			font-weight: 300;

			@media (max-width:992px) {
				padding: 24px;
			}

			p {
				line-height: 1.8;
			}

			ul {

				li {
					margin-bottom: 10px;

					span {
						min-width: 250px;
						display: inline-block;

						@media (max-width:550px) {
							display: block;
						}
					}
				}
			}

			.btn {
				letter-spacing: 1.5px;
				font-size: 13px;
			}
		}
	}

	.items-slide {
		text-align: center;

		.item {
			max-width: 390px;
			display: inline-block;
			background-size: cover;
			background-position: 50%;
			background-repeat: no-repeat;
			width: 100%;
			text-align: center;
			margin-top: 20px;

			.offer-img-bl {
				position: relative;

				img {
					object-fit: cover;
					object-position: center;
					height: 300px;
					width: 100%;
				}
			}

			p {
				min-height: 59px;
			}


			.item-offer-bl {
				background: #fff;
				position: relative;
				top: -50px;
				padding: 20px 10px 0;
				text-align: center;

				@media (min-width:551px) {
					min-height: 170px;
				}


				display: inline-block;
				max-width: 320px;
				text-align: center;
				color: var(--black);

				.link {
					color: var(--primary);
					letter-spacing: 1px;

					&:hover {
						color: var(--secondary);
					}
				}
			}
		}

		.owl-nav {
			position: absolute;
			top: 40%;
			left: 0;
			width: 100%;



			button {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				border: 1px solid #b1b1b1;
				border-radius: 50%;
				box-shadow: 0 0 8px rgba(0, 0, 0, .21);
				background-color: var(--white);
				position: absolute;
				left: -60px;

				@media (max-width:1300px) {
					left: 0;
				}

				span {
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					-webkit-font-smoothing: antialiased;
					display: inline-block;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					line-height: 1;
					color: transparent;

					&:before {
						color: var(--primary);
						font-family: "Font Awesome 5 Free";
						position: relative;
						left: 2px;
					}
				}

				&.owl-next {
					position: absolute;
					right: -60px;
					left: initial;

					@media (max-width:1300px) {
						right: 0;
					}

					span {

						&:before {
							content: "\f061";
						}
					}
				}

				&.owl-prev {
					span {

						&:before {
							content: "\f060";

						}
					}
				}
			}
		}
	}



	.why-book-us {
		padding: 0 15px;

		.row {
			display: flex;
			flex-flow: wrap;
		}

		.why-ct-bl {
			background: #ffffff;
			text-align: center;
			padding: 30px 16px;
			border-right: 1px solid lightgrey;

			&:last-child {
				border-right: none !important;
			}

			@media (max-width:1200px) {
				border-bottom: 1px solid lightgrey;


				&:nth-child(3n) {
					border-right: none;
				}
			}

			@media (max-width:767px) {

				&:nth-child(2n),
				&:nth-child(4n) {
					border-right: none;
				}

				&:nth-child(3n) {
					border-right: 1px solid lightgrey;
				}
			}

			.icon-img {
				display: inline-block;
				margin-bottom: 13px;
			}

			h5 {
				font-size: 14px;
				margin: 0 0 6px;
			}

			p {
				font-size: 12px;
			}
		}
	}

	.latest-bl-area {

		.bl-img {
			position: relative;
			width: 100%;

			@media (min-width:551px) {
				width: 50%;
			}

			img {
				-o-object-fit: cover;
				object-fit: cover;
				-o-object-position: center;
				object-position: center;
				height: 285px;
				width: 100%;
			}
		}

		.bl-small {
			margin-bottom: 15px;

			@media (min-width:551px) {
				display: flex;
				margin-bottom: 50px;
			}

			@media (max-width:767px) {}

			&.bl-reverse {
				@media (min-width:551px) {
					flex-direction: row-reverse;
				}
			}
		}

		.lt-bl-cont {
			background: var(--light-grey);
			padding: 20px;
			text-align: center;
			display: inline-block;
			width: 100%;
			color: var(--black);

			@media (min-width:551px) {
				max-width: 50%;
				padding: 40px 35px;
			}

			.bl-title {
				letter-spacing: 1px;
				font-size: 19px;
				margin-bottom: 30px;
			}

			.date {
				font-size: 13px;
				margin-bottom: 20px;
				color: var(--darkgrey);
				font-weight: normal;

			}
		}
	}

	.home-unit-view {
		overflow: hidden;

	}


	.testimonials-bl {
		border-top: 1px solid #efefef;

		.client-testimonial {
			padding: 0 55px;

			.owl-nav {
				button {
					width: 24px;
					height: 24px;
					box-shadow: inherit;
					-webkit-box-shadow: inherit;


					&.owl-prev {
						span {
							&:before {
								content: "\f104";
								color: var(--grey2);
							}
						}
					}

					&.owl-next {
						span {
							&:before {
								content: "\f105";
								color: var(--grey2);
							}
						}
					}
				}
			}
		}



		.review-side-bl {
			text-align: center;

			@media (max-width:767px) {
				margin-top: 30px;
			}

			h3 {
				font-size: 26px;
				margin-bottom: 0px;
			}

			.ratings {
				margin-top: 18px;
				margin-bottom: 12px;
				display: block;
				text-align: center;

				i {
					font-size: 18px;
					margin: 1px;
				}
			}

			p {
				font-size: 13px;
				margin-bottom: 18px;
			}
		}

		.itm-testi-bl {
			position: relative;

			h4 {
				font-size: 15px;
				margin-top: 14px;
				margin-bottom: 14px;
			}

			dt {
				color: rgba(0, 0, 0, .5);
				font-size: 12px;
				position: absolute;
				right: 0;
				top: 0;
				font-weight: normal;
			}


			p {
				margin-bottom: 24px;
			}

			.client-name {
				font-size: 12px;
			}
		}

		.ratings {
			display: flex;

			i {
				margin-right: 2px;
				padding: 4px;
				background: var(--green);
				color: var(--white);
				font-size: 14px;
			}
		}
	}

	.latest-deals {
		.owl-nav {
			margin-top: 35px;

			.owl-prev {
				margin-left: -15px;
			}

			.owl-next {
				margin-right: -15px;
			}

			span:before {
				color: var(--black);
			}
		}

	}

	.blog-block {
		background-color: var(--white);
		margin-bottom: 30px;

		&:hover {
			background-color: var(--grey3);
		}

		.bl-img {
			height: 500px;

			img {
				-object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.lt-bl-cont {
			padding: 30px;

			.blog-title {
				font-size: 18px;
				color: var(--black2);

				&:hover {
					color: #626872;
				}
			}

			dt {
				font-size: 12px;
				font-weight: 400;
			}

			.tags {
				margin-top: 20px;
				margin-left: -5px;

				li {
					display: inline-block;
					margin: 5px;
					background-color: var(--grey3);
					padding: 5px 8px;
					border-radius: 3px;
				}
			}
		}
	}

	.covid-popup {
		.modal {
			background: rgb(0 0 0 / 40%);
			overflow: auto;
			height: 100%;

			.modal-content {
				border-radius: 0;
				height: 100%;
				overflow: auto;
			}

			.modal-header {
				h4 {
					margin: 0;
					text-align: center;
				}
			}

			.modal-body {
				padding: 50px 30px;

				@media (max-width: 767px) {
					padding: 50px 10px;
				}
			}

			.modal-dialog {
				@media (max-width: 767px) {
					margin: 30px auto;
				}
			}

			.close {
				background-color: var(--primary);
				opacity: 1;
				color: #fff;
				font-size: 12px;
				width: 25px;
				height: 25px;
				border-radius: 5px;
				position: absolute;
				right: 10px;
				top: 10px;
				z-index: 1;
			}

		}
	}

}