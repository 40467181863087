:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 992px;
	--primary: #f3942e;
	--primary2: #7b818a;
	--secondary: #2e2d2c;
	--black: #211e1f;
	--white: #fff;
	--bodyfont: 'Hind', sans-serif;
	--font-heading: 'Bitter', serif;
	--grey: #9d9d9c;
	--black2: #3c3c3c;
	--li-green: #a5d6d1;
	--li-green2: #82c6bf;
	--green: #7B818A;
	--grey2: #f9f9f9;
	--grey3: #f3f3f3;
	--skinny: #d9ba9f;
	--grey4: #ededed;
	--not-available: #EA655B;
}

/* --green: #888e99 */