@font-face {
	font-family: 'Bitter';
	src: url('RESOURCE/fonts/Bitter-Bold.eot');
	src: url('RESOURCE/fonts/Bitter-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Bitter-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Bitter-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Bitter-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/Bitter-Bold.svg#Bitter-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Bitter';
	src: url('RESOURCE/fonts/Bitter-Regular.eot');
	src: url('RESOURCE/fonts/Bitter-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Bitter-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Bitter-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Bitter-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Bitter-Regular.svg#Bitter-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Hind';
	src: url('RESOURCE/fonts/Hind-Light.eot');
	src: url('RESOURCE/fonts/Hind-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Hind-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Hind-Light.woff') format('woff'),
		url('RESOURCE/fonts/Hind-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/Hind-Light.svg#Hind-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Hind';
	src: url('RESOURCE/fonts/Hind-Regular.eot');
	src: url('RESOURCE/fonts/Hind-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Hind-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Hind-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Hind-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Hind-Regular.svg#Hind-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Hind';
	src: url('RESOURCE/fonts/Hind-Bold.eot');
	src: url('RESOURCE/fonts/Hind-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Hind-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Hind-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Hind-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/Hind-Bold.svg#Hind-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Light.eot');
	src: url('RESOURCE/fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Light.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Light.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Light.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Light.svg#Poppins-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Medium.eot');
	src: url('RESOURCE/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Medium.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Medium.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Medium.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Regular.eot');
	src: url('RESOURCE/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}


@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-SemiBold.eot');
	src: url('RESOURCE/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-SemiBold.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-SemiBold.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-SemiBold.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('RESOURCE/fonts/Poppins-Bold.eot');
	src: url('RESOURCE/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Poppins-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/Poppins-Bold.woff') format('woff'),
		url('RESOURCE/fonts/Poppins-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}

.wrap-btn {
	white-space: inherit;
	height: auto !important;
}

.asd__mobile-close {
	color: var(--white);
	position: absolute;
	top: 6px;
	right: 16px;
	z-index: 50;
	opacity: 1;
	font-size: 24px;
	background-color: #000;
	background-color: var(--black);
	line-height: 1;
	padding: 3px 10px;
}

.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}

.row {
	display: flex;
	flex-wrap: wrap;
}

.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

/* datepicker 2 
.datepicker-trigger {
	--cal_not_available: #cd7898;

	.asd__day--not-available {
		background: var(--cal_not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(90deg, white 70%, var(--cal_not_available) 80%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, white 30%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, #e4e7e7 70%, var(--cal_not_available) 80%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, #e4e7e7 30%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: rgb(102, 226, 218) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: rgb(103, 246, 238) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: rgba(86, 90, 92, 0.5) !important;
	}

	.asd__day--selected {
		background: #357ebd !important;
		color: white !important;
		opacity: 1 !important;

		&.asd__selected-date-one {
			border-radius: 4px 0 0 4px;
		}
	}

	.asd__day--in-range {
		background: #ebf4f8 !important;
		color: var(--green) !important;
	}

	.asd__day--enabled {
		border: none !Important;

		&.asd__day--today {
			background-color: #357ebd;
			border-color: transparent;
			color: var(--white) !important;
			border-radius: 4px;
		}


		&.asd__day--hovered {
			background: #ebf4f8 !important;
			color: var(--green) !important;
		}
	}

	.asd__day--selected {
		background: #357ebd !important;
		color: white !important;
		opacity: 1 !important;
		border-radius: 0 4px 4px 0;
	}


}*/

.datepicker-trigger {
	display: block;

	.asd__day--arrival {
		background: #899a4c;
		background: linear-gradient(135deg, #899a4c 50%, #ea655b 0) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: #ea655b;
		background: linear-gradient(135deg, #ea655b 50%, #899a4c 0) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: #899a4c;
		background: linear-gradient(135deg, #899a4c 50%, #ea655b 0) !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: var(--white) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: #ea655b;
		background: linear-gradient(135deg, #ea655b 50%, #899a4c 0) !important;
	}

	.asd__day-button {
		font-weight: bold !important;
	}

	.asd__day--enabled {
		background: #899a4c;
		cursor: pointer;
		color: #fff !important;
		border: 1px solid #e4e7e7 !Important;

		&.asd__day--disabled {
			color: var(--white) !important;
			background-color: rgb(137, 154, 76, .65) !important;
			opacity: .8;
		}

		&.asd__day--hovered {
			background: #f9dd5c !important;
		}

		&.asd__selected-date-one {
			background: #92802b !important;
			background-image: url(RESOURCE/img/date-start.png) !important;
			background-repeat: no-repeat !important;
			background-size: 15px !important;
			background-position: 100% !important;
			opacity: 1 !important;
		}


		&.asd__day--not-available {
			background: var(--not-available) !important;
			opacity: 1 !important;
			cursor: not-allowed;
			color: #fff !important;
		}

		&:hover {
			background-color: #899a4c;
		}
	}


	.asd__day--in-range {
		background: #f9dd5c !important;
	}

	.asd__day--selected {
		border-radius: 0 !important;

		&.asd__selected-date-two {
			background: #92802b !important;
			background-image: url(RESOURCE/img/date-end.png) !important;
			background-repeat: no-repeat !important;
			background-size: 15px !important;
			background-position: 0 !important;
			opacity: 1 !important;
		}
	}
}


.asd__change-month-button>button>svg {
	fill: var(--primary) !important;
	position: relative;
	top: 2px;

}

.asd__day-button {
	font-size: 14px !important;
}


/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

.modal-body {
	a {
		cursor: pointer;
	}
}

a:focus,
.btn:focus,
span:focus,
button:focus,
label:before:focus,
.btn:focus {
	outline: 0 !important;
	text-decoration: none !important;
}

.checkbox input[type=checkbox]:focus+label:before {
	outline: inherit !important;
	outline: 0 !important;
}

.checkbox-success input[type=checkbox]:checked+label:before {
	background-color: var(--primary);
	border-color: var(--primary);
}


button,
select {
	text-transform: none;
	outline: none;
}


.txt-white {
	color: var(--white);
}

.hide-label {
	display: none;
}

.nav-act .btn+.btn {
	margin-left: 10px;
}

.btn {
	font-size: 13px;
	padding: 8px 16px;
	text-transform: uppercase;
	line-height: 1;

	&.btn-primary,
	&.btn-go {
		background-color: var(--primary);
		color: var(--white);
		border-color: var(--primary);

		&:hover {
			background-color: var(--primary2);
			border-color: var(--primary2);
		}
	}

	&.btn-info {
		color: var(--white);
		background-color: var(--black2);
		border-color: var(--black2);

		&:hover {
			background-color: #232323;
			border-color: #1d1d1d;
		}
	}

	&.btn-danger {
		color: var(--black2);
		background-color: #a5d6d1;
		border-color: #a5d6d1;


		&:hover {
			background-color: #82c6bf;
			border-color: #7bc3bc;
		}
	}

	&.btn-default {
		color: var(--black);
		border-color: var(--primary);

		&.btn-sm {
			font-size: 12px;
		}

		&:hover {
			border-color: var(--primary);
			background-color: var(--primary);
			color: var(--white);
		}
	}

	&.btn-sm {
		font-size: 14px;
		padding: 6px 12px;
	}

	&.btn-success {
		color: var(--white);
		background-color: var(--grey);
		border-color: var(--grey);

		&:hover {
			background-color: #848482;
			border-color: #7f7f7d;
		}
	}

	&.btn-secondary {
		background-color: var(--black2);
		color: var(--white);
		border-color: var(--black2);

		i {
			color: var(--primary);
			margin-right: 5px;
		}

		&:hover {
			background-color: #232323;
			border-color: #1d1d1d;
			color: var(--white);

			i {
				color: var(--white);
			}
		}
	}

	&.btn-warning {
		border-color: var(--primary);
		background-color: var(--primary);

		&:hover {
			background-color: var(--primary2);
			border-color: var(--primary2);
		}
	}

	&.btn-lg {
		padding: 10px 16px;
		font-size: 18px;
		line-height: 1.33333;
		border-radius: 6px;
	}

	&.big-pad {
		padding: 8px 30px;
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}
}

.bl-center {
	display: flex;
	align-items: center;
}

.bl-space-betwen {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width:992px) {
		display: flex;
	}
}

body {
	font-family: var(--bodyfont);
	color: var(--green);
	font-size: 15px;
	font-weight: 400;
}



.container {
	@media (min-width: 1400px) {
		max-width: 1240px;
		width: 100%;
	}
}

p {
	font-weight: 300;
	color: var(--green);
	font-size: 15px;
	line-height: 1.5;
}


a {
	&:hover {
		text-decoration: none;
	}
}

.form-control {
	padding: 7px 5px 7px 32px;
	background-color: #fff;
	background-color: var(--white);
	border: 1px solid #c3c6cc;
	color: #888e99;
	border-radius: 5px;


	&:focus {
		box-shadow: inherit;
	}
}

select.form-control {
	padding: 5px 10px;
}

.nav-tabs {
	border-bottom: none;
	margin-bottom: 5px;



	li {
		&.active {
			a {
				background-color: var(--white);
				color: var(--secondary);
			}
		}

		a {

			background-color: #d8e6f5;
			color: rgba(0, 73, 155, .6);
			text-transform: uppercase;
			border-radius: 0;
			font-size: 13px;
			margin-right: 4px;
			font-weight: 600;
			padding: 10px 15px;

			@media (max-width:550px) {
				font-size: 10px;
				padding: 8px 10px;
			}
		}
	}
}

.tab-content {
	background-color: var(--white);
}

h1.heading-subtxt {
	small {
		line-height: 1.2em;
		display: block;
		color: var(--primary);
		margin-top: 5px;
		font-weight: 400;
		text-transform: initial;
		font-size: 18px;
		font-family: var(--bodyfont);
	}
}

h2.heading-subtxt {
	small {
		line-height: 1.2em;
		display: block;
		color: var(--primary);
		margin-top: 5px;
		font-weight: 400;
		text-transform: initial;
		font-size: 16px;
		font-family: var(--bodyfont);
	}
}

.pad60 {
	padding: 60px 0;

	@media (max-width:550px) {
		padding: 40px 0;
	}
}

.pad30 {
	padding: 30px 0;

	@media (max-width:550px) {
		padding: 20px 0;
	}
}

.no-upper {
	text-transform: initial;
}

.p-0 {
	padding: 0 !important;
}

h2,
.h2 {
	font-size: 26px;

	@media (max-width:992px) {
		font-size: 24px;
	}
}

span,
p {
	font-family: var(--bodyfont);
}

.headline-bl {
	h3 {
		font-size: 26px;
		margin: 0;
	}

	span {
		font-size: 16px;
		text-transform: initial;
		font-weight: 400;
	}
}

.body-text {
	padding-top: 40px;

	p {
		line-height: 2.4em !important;
		font-size: 16px;
	}
}


ul {
	padding: 0;
	margin: 0 0 15px;
	list-style: none;
	padding: 0;
}

.pb-15 {
	padding-bottom: 15px;
}

.pb-30 {
	padding-bottom: 30px !important;
}

b,
strong {
	font-weight: 600;
}

.f16 {
	font-weight: bold;
}

.section-title {

	margin-bottom: 15px;

	&.center {
		text-align: center;
	}

	margin-bottom: 30px;

	.heading1 {
		font-size: 36px;
		display: inline-block;
		position: relative;
		padding-right: 55px;
		margin-bottom: 10px;

		@media (max-width:992px) {
			font-size: 28px;
		}

		padding-bottom:5px;
		border-bottom:1px solid var(--secondary);
	}


	.heading2 {
		font-size: 28px;
		display: block;
		position: relative;

		@media (max-width:992px) {
			font-size: 24px;
		}
	}

	.heading3 {
		font-size: 28px;
		display: inline-block;
		position: relative;
		padding-right: 55px;
		margin-bottom: 10px;

		@media (max-width:992px) {
			font-size: 24px;
		}

		padding-bottom:5px;
		border-bottom:1px solid var(--secondary);
	}

}

h3 {
	font-size: 20px;

	@media (max-width:992px) {
		font-size: 16px;
	}
}

h4 {
	font-size: 18px;

	@media (max-width:992px) {
		font-size: 14px;
	}

	&.f16 {
		font-size: 16px;
	}
}

.big-para {
	line-height: 1.5;
	font-weight: normal;
	font-size: 16px;
}

.mt-10 {
	margin-top: 10px;
}

.pt-50 {
	padding-top: 50px;
}

.bg-grey {
	background-color: var(--grey3);
}

.li-grey {
	background-color: var(--light-grey);
}

hr {
	border-top: 1px solid #f3f3f3;

	&.divider {
		margin-top: 45px;
		margin-bottom: 45px;
		border-color: #c3c6cc;

		@media (max-width:992px) {
			margin-top: 25px;
			margin-bottom: 25px;
		}

		&.big-gap {
			margin-top: 30px;
			margin-bottom: 30px;
			border-color: #f3f3f3;

			@media (max-width:992px) {
				margin-top: 45px;
				margin-bottom: 45px;
			}
		}
	}
}

.flex-row-reverse {
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.link {
	color: var(--primary);
	letter-spacing: 1px;
}



.pb-0 {
	padding-bottom: 0 !important;
}

.pb-30 {
	padding-bottom: 30px;
}

.pull-sm-right {
	@media (min-width: 768px) {
		float: right;
	}
}

a {
	color: var(--primary);

	&:hover {
		color: var(--secondary);
	}
}



.checkbox-success input[type=checkbox]:checked+label:before {
	background-color: var(--white) !important;
}

.checkbox label:after {
	padding-left: 2px !important;
	font-size: 10px !important;
	margin-left: -18px !important;
	padding-top: 0 !important;
	color: var(--green) !important;
	margin-top: -1px;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mt-20 {
	margin-top: 20px !important;
}


.mb-0 {
	margin-bottom: 0 !important;
}

.checkbox {
	label {
		padding-left: 10px !important;
		line-height: 20px;

		&:before {
			width: 17px !important;
			height: 17px !important;
			border-color: #c3c6cc;
		}
	}
}

.tick-text {
	li {
		margin-bottom: 5px;

		.fa {
			color: var(--secondary);
			margin-right: 8px;
		}
	}
}

.btn-block+.btn-block {
	margin-top: 5px;
}

.mb-30 {
	margin-bottom: 30px;
}

.pad40 {
	padding: 40px 0;
}

.min-row {
	margin: 0 -7px;

	.col-md-7,
	.col-md-5,
	.col-md-6,
	.col-md-8,
	.col-md-4,
	.col-md-9,
	.col-md-3,
	.col-sm-7,
	.col-sm-5 {
		padding: 0 7px;
	}
}

.form-horizontal {
	.control-label {
		text-align: left;
		padding-bottom: 5px;
	}
}

.contact-bl {
	display: flex;
	margin-bottom: 15px;
	position: relative;
	padding-left: 60px;
	min-height: 40px;
	align-items: center;

	&:last-child {
		margin-bottom: 0;
	}

	a {
		color: var(--black);
	}


	i {
		color: var(--primary);
		background-color: var(--white);
		padding: 5px;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		left: 0;
		top: 2px;
		box-shadow: 0 0 10px rgba(0, 0, 0, .18);
		font-size: 18px;
	}
}

.aside-widget {
	padding: 20px 15px;
	border-bottom: 1px solid #e2e2e2;
	background-color: var(--light-grey);
	font-size: 13px;

	&:last-child {
		border-bottom: none;
	}
}



.three-units {

	.col-md-4,
	.col-sm-4 {
		display: none;

		&:first-child {
			display: block !important;
		}

		&:nth-child(2),
		&:nth-child(3) {
			display: block !important;
		}
	}
}

.six-units {
	.col-md-4 {
		display: none;

		&:nth-child(-n+6) {
			display: block;
		}
	}
}

.pt-0 {
	padding-top: 0 !important;
}



.hide-mob {
	display: block;

	@media (max-width:767px) {
		display: none;
	}
}

.show-mob {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.cetner-text {
	text-align: center;
}

.price-table {
	.btn {
		padding: 4px 10px;
		margin-right: 8px;

		&.btn-warning.btn-sm {
			font-size: 12px;
		}

	}

	.quote-hint {
		font-size: 13px;
		padding-top: 10px;
	}
}

.asd__mobile-header h3 {
	color: var(--black);
}

.asd__mobile-close {
	background-color: var(--black);
}

.guestbox {
	.btn {
		width: 36px !important;
		height: 36px !important;
		font-size: 24px !important;
		line-height: 31px !important;
	}
}

.print-visible {
	display: none;
}

.checkbox-success input[type=checkbox]:checked+label:before {
	border-color: var(--primary) !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mb-10 {
	margin-bottom: 10px !important;
}


.guestbox {
	label {
		font-size: 13px;
		margin-bottom: 0;
	}
}

.pad30 {
	padding: 30px 0;
}

h1 {
	font-size: 30px;
}

h1,
h2,
h3,
h4,
.headline {
	text-transform: uppercase;
	font-family: var(--font-heading);
	margin-top: 0;
	color: var(--black2);
	font-weight: 300;
	line-height: 1.2em;

	small {
		line-height: 1.2em;
		display: block;
		color: var(--primary);
		margin-top: 5px;
		font-weight: 400;
		text-transform: initial;
		font-size: 16px;
		font-family: var(--bodyfont);
	}
}

.single-unit {
	width: 100%;
	padding: 0;
	margin: 0 auto 30px;
	/*max-width: 500px;*/
	transition: all 0.25s ease 0s;
	position: relative;
	border-top: 2px solid #c3c6cc;
	border-bottom: 2px solid #c3c6cc;
	background-color: var(--grey2);
	padding-top: 2px;

	&:hover {
		background-color: var(--grey3);

		.unit-img {
			img {
				transform: scale(1.1);
			}
		}
	}

	@media (max-width:499px) {
		max-width: 340px;
	}

	@media (max-width:374px) {
		max-width: 290px;
	}

	.objnr {
		position: absolute;
		right: 15px;
		top: 0px;
		z-index: 2;
		background-color: var(--primary);
		color: var(--white);
		text-align: center;
		line-height: 30px;
		font-size: 14px;
		padding: 5px 15px;
		border-radius: 5px;
		font-weight: 700;
	}

	.unit-img {
		position: relative;
		display: block;
		overflow: hidden;

		.new-un-tag {
			position: absolute;
			bottom: 15px;
			left: 0;
			font-size: 18px;
			background: #a5d6d1;
			z-index: 5;
			padding: 8px 15px;
			color: var(--black2);
			border-radius: 0 5px 5px 0;
			text-transform: uppercase;

			. span {
				font-family: var(--font-heading);

				i {
					font-size: 15.5px;
					color: #565a5c;
				}
			}
		}

		.fav-icon {
			color: var(--primary);
			font-size: 22px;
			position: absolute;
			right: 14px;
			z-index: 2;
			bottom: 10px;
			cursor: pointer;
		}

		.item {
			padding-bottom: 300px;
			overflow: hidden;
			position: relative;
		}

		img {
			width: 100%;
			transform: scale(1);
			transition: all 0.5s ease 0s;
			position: absolute;
			object-position: center;
			object-fit: cover;
			height: 100%;

		}

		.owl-dots {
			display: none;
		}

		.owl-nav {
			position: absolute;
			top: 40%;
			left: 0;
			width: 100%;

			button {
				width: 40px;
				height: 40px;
				position: absolute;
				left: 0;

				span {
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					-webkit-font-smoothing: antialiased;
					display: inline-block;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					line-height: 1;
					color: transparent;
					font-size: 34px;

					&:before {
						color: var(--white);
						font-family: "Font Awesome 5 Free";
						position: relative;
						left: 2px;
					}
				}

				&.owl-next {
					position: absolute;
					right: 6px;
					left: initial;

					span {

						&:before {
							content: "\f054";
						}
					}
				}

				&.owl-prev {
					span {
						left: 6px;
						position: relative;

						&:before {
							content: "\f053";

						}
					}
				}
			}
		}

	}

	.unit-cont-bl {
		padding: 10px 15px 15px;

		.unit-title {
			color: var(--black2);
			font-size: 18px;
			margin: 0;
			padding-bottom: 8px;
			text-transform: uppercase;
			min-height: 55px;
			/*white-space: nowrap;
			text-overflow: ellipsis;*/

			&:hover {
				color: var(--primary);
			}
		}

		.unit-address {
			font-size: 16px;
			color: var(--green);
			font-weight: 400;
			margin-bottom: 15px;

			i {
				display: inline-block;
				margin-right: 5px;
			}
		}


		.unit-feature-icons {
			position: absolute;
			top: 20px;
			width: 45px;
			left: 0;
			z-index: 2;

			i {
				background-image: url(RESOURCE/img/sprite-icon-unit-ft.png);
				background-repeat: no-repeat;
				background-position: center;
				background-color: var(--black);
				height: 35px;
				width: 45px;
				margin-bottom: 5px;
				border-radius: 0 5px 5px 0;
				display: inline-flex;

				&:before {
					content: "";
				}

				&.fa-paw {
					&.cross {
						background-position: -105px 0;
					}
				}

				&.fa-nosmoke {
					background-position: 2px 0;
				}

				&.fa-smoke {
					background-image: url(RESOURCE/img/icon-smoke.png);
				}
			}
		}

		>.ratings {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;
			min-height: auto;
			flex-wrap: wrap;

			.rat-icon-bl {
				display: block;

				.fa-star {
					color: #FECA02;
					font-size: 18px;

					&.fa-star-half {
						background: #c3c6cc;
						background: -moz-linear-gradient(45deg, #61847a 0%, #61847a 50%, #c3c6cc 51%, #c3c6cc 100%);
						background: -webkit-linear-gradient(45deg, #61847a 0%, #61847a 50%, #c3c6cc 51%, #c3c6cc 100%);
						background: -o-linear-gradient(45deg, #61847a 0%, #61847a 50%, #c3c6cc 51%, #c3c6cc 100%);
						background: linear-gradient(45deg, #61847a 0%, #61847a 50%, #c3c6cc 51%, #c3c6cc 100%);
					}
				}
			}

			.rating2 {

				>.ratings {
					display: inline-block;
					margin-right: -4px;
				}

				span:last-child {
					display: block;
				}


				>div {
					align-items: flex-end;
					justify-content: flex-end;
					display: flex;
				}

				.fa {
					width: 15px;
					height: 15px;
					border-radius: 50%;
					display: inline-block;
					background-color: var(--primary);
					border: 2px solid var(--primary);

					&:before {
						content: "";
					}

					&.fa-star-half-o {
						background: rgb(97, 132, 122);
						background: linear-gradient(90deg, rgba(97, 132, 122, 1) 51%, rgba(255, 255, 255, 1) 50%);
					}

					&.fa-star-o {
						background-color: transparent;
					}
				}

				span {
					font-size: 16px;
					color: var(--green);
					display: inline-block;
					position: relative;
					top: -2px;
					margin-left: 5px;
				}
			}
		}




		.unit-link {
			text-transform: uppercase;
			color: var(--primary);
			font-size: 12px;
			font-weight: bold;
			position: relative;
			top: 9px;

			&:hover {
				color: var(--secondary);
			}
		}

		.unit-properties-li {
			padding-left: 15px;
			min-height: 90px;

			li {
				display: block;
				color: var(--green);
			}
		}

		.unit-rating {
			font-size: 14px;
			min-height: 22px;
			display: block;

			.stars {
				display: inline-block;
			}

			i {
				color: var(--green);
			}

			span {
				color: var(--black);
			}
		}
	}
}

.headline {

	h2,
	span {
		background-color: var(--white);
		padding: 10px 30px;
		margin-bottom: 15px;
		display: inline-block;
		font-weight: 400;
	}

	span {
		text-transform: initial;
		font-size: 16px;
	}
}

.inner-template {
	padding-top: 150px;
}

.heading-sect-bl {
	padding-bottom: 9px;
	margin: 40px 0 20px;
	border-bottom: 1px solid var(--grey3);
}

.icon-caret {
	position: relative;

	&:before {
		display: inline-block;
		width: 0;
		height: 0;
		margin-left: 2px;
		vertical-align: middle;
		border-top: 4px solid var(--white);
		border-right: 4px solid transparent;
		border-left: 4px solid transparent;
		content: "";
	}
}

.dot-li {
	list-style: disc;
	margin-left: 0;
	padding: 0;
	margin-bottom: 10px;

	li {
		color: var(--green);
		padding: 5px 0 5px 15px;
		list-style: none;
		position: relative;

		&:before {
			background-color: var(--green);
			width: 5px;
			height: 5px;
			border-radius: 50%;
			content: "";
			position: absolute;
			left: 0;
			top: 11px;
		}

			{
			a {
				text-decoration: underline;

				&:hover {
					color: #626872;
					text-decoration: underline;
				}
			}
		}
	}

}

.double-arrow-li {
	list-style: none;

	li {
		padding: 5px 30px;

		&:before {
			font-family: FontAwesome;
			content: "\f101";
			width: 30px;
			position: absolute;
			left: 16px;
			font-weight: normal;
			color: var(--green);
			font-size: 12px;
		}

		a {
			color: var(--green);

			&:hover {
				color: #626872;
				text-decoration: underline;
			}
		}
	}
}

.flex-align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

p {
	a {
		text-decoration: underline;

		&:hover {
			color: #626872;
			text-decoration: underline;
		}
	}
}



.breadcrumb {
	background-color: #fff !important;
	padding-left: 0px;
	padding-bottom: 0;

}

.breadcrumb li {
	background-color: #c3c6cc !important;
	padding: 5px 10px 5px 20px;
	margin-right: 1px;
	position: relative;
	color: var(--white);

	@media (max-width:992px) {
		margin: 2px;
	}

	&.breadcrumb-item {
		&:hover {
			background-color: var(--primary) !important;

			&:after {
				border-left: 10px solid var(--primary);
			}
		}
	}

	.fa-home {
		font-size: 12px;
	}
}


.breadcrumb li a,
.breadcrumb li span {
	color: var(--white);
}


.breadcrumb li:after {
	content: '';
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 10px solid #c3c6cc;
	position: absolute;
	right: -10px;
	margin: 0;
	width: auto;
	top: 0;
	z-index: 1;
}

.breadcrumb li+li:before {
	content: '';
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 10px solid #ffffff;
	position: absolute;
	left: 0px;
	margin: 0;
	width: auto;
	top: 0;
	z-index: 0;
}



.asd__wrapper--full-screen {
	@media (max-width:992px) {
		padding-top: 130px;
	}
}

.box.full-xxs .guestbox {
	@media (max-width:576px) {
		margin-top: 130px;
	}
}

.doc-box {
	.dlg {
		color: var(--black2);

		ol {
			padding: 0;
		}

		strong {
			color: var(--black2);
		}

		a {
			color: var(--primary) !important;
		}
	}
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
	background-color: var(--primary);
	border-color: var(--primary);
}

.pagination>li>a,
.pagination>li>span {
	color: var(--primary);
}


.asd__mobile-close {
	background-color: var(--black);
	color: var(--white);
	width: 36px;
	border-radius: 5px;
	margin-right: 10px;
}

.fold-enter-active,
.fold-leave-active {
	transition: opacity .5s;
}

.fold-enter,
.fold-leave-to,
.fade-leave-active {
	opacity: 0;
}

.float-right {
	float: right;
}

.span-w-100 span {
	width: 100%;
	margin: 0 0 10px 0;
	display: block;
}

.m-auto {
	margin: 20px auto;
}

.max-height-350 {
	max-width: 380px;
	height: 350px;
	object-fit: cover;
	width: 100%;

	@media (max-width: 575px) {
		height: 280px;
	}

}

.pb-20 {
	padding-bottom: 20px;
}

p.arrows {
	position: relative;
	padding-left: 25px;

	&:before {
		font-family: FontAwesome;
		content: "\f101";
		width: 30px;
		position: absolute;
		left: 16px;
		font-weight: 400;
		font-size: 12px;
		left: 0;
	}

	a {
		word-break: break-all;
	}
}

.button-height-10 {
	padding: 13px 10px;
}